import DATA from "@/assets/json/olympics.json";
import { ref, onBeforeMount } from "vue";
import { ColHeader, GridData } from "./AgGrid.interface";

export const handleAgGrid = () => {
  const rowHeight = ref<number>(0);
  const rowData = ref<Array<GridData>>([]);
  const columnDefs = ref<Array<ColHeader>>([]);
  const gridOptions = {
    alignedGrids: [],
    defaultColDef: {
      editable: true,
      sortable: true,
      resizable: true,
      filter: true,
      minWidth: 100,
    },
    enableRangeSelection: true,
    enableFillHandle: true,
    undoRedoCellEditing: true,
    undoRedoCellEditingLimit: 5,
    debounceVerticalScrollbar: true,
    groupMultiAutoColumn: true,
  };
  const search = ref<string | number>("");
  const api = ref();

  onBeforeMount(() => {
    rowData.value = DATA;

    const columns = Object.keys(DATA[0]);
    columns.forEach((colname) => {
      columnDefs.value.push({ field: colname });
    });
  });

  const onGridReady = (params) => {
    api.value = params.api;
  };

  const getRowHeight = (params) => {
    rowHeight.value = params.node.rowHeight;
  };

  const setTableHeight = () => {
    const tableHeight = rowHeight.value * rowData.value.length;
    return tableHeight;
  };

  const searchData = (e) => {
    search.value = e.target.value;
    api.value.setQuickFilter(search.value);
  };

  return {
    gridOptions,
    search,
    searchData,
    onGridReady,
    rowData,
    columnDefs,
    getRowHeight,
    setTableHeight,
  };
};
