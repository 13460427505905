<template>
  <!--begin::Tables Widget 10-->
  <div :class="widgetClasses" class="card">
    <!--begin::Header-->
    <div class="card-header border-0 pt-5" v-if="!hideHeader">
      <h3 class="card-title align-items-start flex-column">
        <span class="card-label fw-bolder fs-3 mb-1">New Products</span>
        <!-- <button @click="showToast">Show Toast</button> -->
        <!-- <AgGridSwitch />
        <AgGridDatePicker /> -->
        <span class="text-muted mt-1 fw-bold fs-7">Over 500 new products</span>
      </h3>
      <div class="card-toolbar">
        <!--begin::Menu-->
        <button
          type="button"
          class="btn btn-sm btn-icon btn-color-primary btn-active-light-primary"
          data-kt-menu-trigger="click"
          data-kt-menu-placement="bottom-end"
          data-kt-menu-flip="top-end"
        >
          <span class="svg-icon svg-icon-2">
            <inline-svg
              src="media/icons/duotone/Layout/Layout-4-blocks-2.svg"
            />
          </span>
        </button>
        <Dropdown2></Dropdown2>
        <!--end::Menu-->
      </div>
    </div>
    <!--end::Header-->

    <!--begin::Body-->
    <div class="card-body pt-3">
      <!--begin::Table container-->
      <input
        v-if="!enableSearch"
        type="text"
        class="form-control form-control-lg form-control-solid"
        name="appName"
        placeholder="Start typing to search table"
        :value="search"
        @input="searchData"
      />
      <slot name="test"></slot>
      <div class="table-responsive mt-5">
        <!--begin::Table-->
        <AgGridVue
          style="width: 100%"
          :style="`height: ${enableFullpage ? setTableHeight() : '500'}px`"
          class="ag-theme-alpine"
          :class="enableFullpage ? 'fullPage' : ''"
          :columnDefs="columnDefs"
          :rowData="rowData"
          :gridOptions="gridOptions"
          :pagination="enablePagination"
          @grid-ready="onGridReady"
          :getRowHeight="getRowHeight"
        />
        <!--end::Table-->
      </div>
      <!--end::Table container-->
    </div>
    <!--begin::Body-->
  </div>
  <!--end::Tables Widget 10-->
</template>

<script lang="ts">
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { AgGridVue } from "ag-grid-vue3";
import { LicenseManager } from "ag-grid-enterprise";
LicenseManager.setLicenseKey(process.env.VUE_APP_AGGRID_KEY as string);

import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import { Actions as AggridActions } from "@/store/enums/AggridEnums";

import { defineComponent } from "vue";
import Dropdown2 from "@/components/dropdown/Dropdown2.vue";

import { handleAgGrid } from "./AgGrid";

export default defineComponent({
  name: "AgGrid",
  components: {
    Dropdown2,
    AgGridVue,
  },
  props: {
    widgetClasses: String,
    enablePagination: {
      type: Boolean,
      required: false,
    },
    enableSearch: {
      type: Boolean,
      required: false,
    },
    enableFullpage: {
      type: Boolean,
      required: false,
    },
    hideHeader: {
      type: Boolean,
      required: false,
    },
  },
  setup() {
    const store = useStore();
    const {
      gridOptions,
      search,
      searchData,
      onGridReady,
      rowData,
      columnDefs,
      getRowHeight,
      setTableHeight,
    } = handleAgGrid();

    const showToast = () => {
      store.dispatch(AggridActions.SET_UNDO_ACTION, {
        type: "success",
        title: "Updating....",
        allowUndo: true,
        duration: 5000,
      });
    };

    return {
      gridOptions,
      search,
      searchData,
      onGridReady,
      rowData,
      columnDefs,
      getRowHeight,
      setTableHeight,
      showToast,
    };
  },
});
</script>

<style>
.fullPage {
  overflow: hidden;
}

.fullPage .ag-body-viewport.ag-layout-normal {
  overflow-y: hidden !important;
}
</style>