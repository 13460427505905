
import { defineComponent } from "@vue/runtime-core";

import AgGrid from "@/components/widgets/tables/aggrid/AgGrid.vue";
import AgGridFullPage from "@/components/modals/general/AgGridFullModal.vue";

export default defineComponent({
  name: "purchaseinvoice",
  components: {
    AgGrid,
    AgGridFullPage,
  },
});
