
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { AgGridVue } from "ag-grid-vue3";
import { LicenseManager } from "ag-grid-enterprise";
LicenseManager.setLicenseKey(process.env.VUE_APP_AGGRID_KEY as string);

import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import { Actions as AggridActions } from "@/store/enums/AggridEnums";

import { defineComponent } from "vue";
import Dropdown2 from "@/components/dropdown/Dropdown2.vue";

import { handleAgGrid } from "./AgGrid";

export default defineComponent({
  name: "AgGrid",
  components: {
    Dropdown2,
    AgGridVue,
  },
  props: {
    widgetClasses: String,
    enablePagination: {
      type: Boolean,
      required: false,
    },
    enableSearch: {
      type: Boolean,
      required: false,
    },
    enableFullpage: {
      type: Boolean,
      required: false,
    },
    hideHeader: {
      type: Boolean,
      required: false,
    },
  },
  setup() {
    const store = useStore();
    const {
      gridOptions,
      search,
      searchData,
      onGridReady,
      rowData,
      columnDefs,
      getRowHeight,
      setTableHeight,
    } = handleAgGrid();

    const showToast = () => {
      store.dispatch(AggridActions.SET_UNDO_ACTION, {
        type: "success",
        title: "Updating....",
        allowUndo: true,
        duration: 5000,
      });
    };

    return {
      gridOptions,
      search,
      searchData,
      onGridReady,
      rowData,
      columnDefs,
      getRowHeight,
      setTableHeight,
      showToast,
    };
  },
});
