<template>
  <div class="row gy-5 gx-xl-8">
    <div class="col-xxl-12">
      <AgGrid widget-classes="card-xxl-stretch" enable-pagination>
        <template #test>
          <div class="text-end">
            <!-- <button
              type="button"
              class="
                btn btn-sm btn-icon btn-color-primary btn-active-light-primary
              "
              src="media/icons/duotone/Navigation/Up-right.svg"
              data-bs-toggle="modal"
              data-bs-target="#kt_modal_aggrid_full"
              id="kt_test"
            >
              <span class="svg-icon svg-icon-2">
                <inline-svg src="media/icons/duotone/Navigation/Up-right.svg" />
              </span>
            </button> -->
          </div>
        </template>
      </AgGrid>
    </div>
    <AgGridFullPage>
      <AgGrid
        widget-classes="card-xxl-stretch"
        hide-header
        enable-fullpage
      ></AgGrid>
    </AgGridFullPage>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "@vue/runtime-core";

import AgGrid from "@/components/widgets/tables/aggrid/AgGrid.vue";
import AgGridFullPage from "@/components/modals/general/AgGridFullModal.vue";

export default defineComponent({
  name: "purchaseinvoice",
  components: {
    AgGrid,
    AgGridFullPage,
  },
});
</script>

<style>
</style>